import React, { useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import "aos/dist/aos.css"; // You can also use <link> for styles

import Layout from "@components/layout/layout";
import "@styles/styles.scss";
import image1 from "@images/monkeybars800x600.jpeg";

const StyledLink = styled(Link)`
  outline: none;
`;

const PricingPage = () => {
  let AOS;
  useEffect(() => {
    /**
     * Server-side rendering does not provide the 'document' object
     * therefore this import is required either in useEffect or componentDidMount as they
     * are exclusively executed on a client
     */
    const AOS = require("aos");
    AOS.init({
      once: false,
    });
  }, []);

  useEffect(() => {
    if (AOS) {
      AOS.refresh();
    }
  });

  return (
    <Layout pageTitle="Pricing">
      <div style={{ height: "10vh" }}></div>

      <section className="section">
        <div className="container">
          {/* <div className="column is-12 intro">
            <h1 className="title has-text-centered section-title">Pricingdd</h1>
          </div> */}

          {/********************************** */}
          <div className="columns is-multiline is-vcentered">
            <div
              className="column is-6 has-vertically-aligned-content"
              // data-aos="fade-right"
              // data-aos-duration="1"
            >
              <div className="container">
                <div className="container has-text-centered">
                  <p className="is-size-3">Pricing</p>
                  <br></br>
                </div>
                <div className="container">
                  <p>
                    Our mission at My Boulder Nanny is to do all of the work to
                    find and pre-screen safe and reliable care providers to help
                    our community of parents get back to work faster. We
                    understand that a reliable nanny who provides peace-of-mind
                    is a priceless piece of the productivity puzzle.
                  </p>
                  <br />
                  <p>
                    Our founder, Jimie, understands how important Nannies are
                    for getting our economy back up and running! When she
                    graduated from CU Boulder in 2010 during the peak of the
                    last recession, Nannying was the most rewarding and fun job!
                    We hope to make the placements fantastic matches for our
                    Nannies as well! The importance of legal Nanny / Household
                    staff employment has never been more apparent. We are laying
                    the foundation for Nanny/Family/Agency relationships that
                    last for years and decades to come.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="column is-6 right-image ">
              <img src={image1} alt="" />
            </div>
          </div>

          {/********************************** */}

          <div className="columns">
            <div className="column is-12">
              <div>
                <div className="columns is-multiline is-vcentered">
                  <div className="column is-6 section-color dark-info-box">
                    <div
                      className="container has-text-centered"
                      data-aos="fade-in"
                      data-aos-easing="linear"
                    >
                      <p className="is-size-4">Promos</p>
                    </div>
                    <br></br>
                    <ul>
                      <li>
                        <div data-aos="fade-in" data-aos-easing="linear">
                          <p className="has-text-weight-bold">
                            My Boulder Babysitter Member Families
                          </p>
                        </div>
                        <div data-aos="fade-in" data-aos-easing="linear">
                          <p className="">10% discount</p>
                        </div>
                      </li>
                    </ul>
                    {/* <br></br> */}
                    {/* <div data-aos="fade-in" data-aos-easing="linear">
                      <p className="is-size-7">
                        **Discounts can be combined!**
                      </p>
                    </div> */}
                  </div>

                  <div className="column is-6 has-vertically-aligned-content">
                    <div className="container">
                      <p className="subtitle is-size-4 has-text-centered">
                        Get Started
                      </p>
                      <p>
                        We vet families almost as much as we vet our nannies.
                        This ensures a safe and happy fit for both parties.
                      </p>
                      <br></br>
                      <p className="has-text-weight-bold">
                        {"  "}$195 registration (one-time)
                      </p>
                      <p className="has-text-weight-bold">
                        {"  "}$225 per new search
                      </p>
                      <p className="has-text-weight-bold">
                        {"  "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/********************************** */}

          <div className="columns">
            <div className="column is-12">
              <div className="box">
                <h3 className="subtitle is-3 has-text-centered">
                  Permanent Nanny Placements
                </h3>
                <p className="subtitle has-text-centered">
                  >12 week commitment
                </p>
                <div className="pricing-table">
                  <div className="pricing-plan is-info">
                    <div className="plan-header"> 30 + hours / week</div>
                    <div className="plan-price">
                      <span className="plan-price-amount">
                        <span className="plan-price-currency"></span>10
                      </span>
                      % of salary<span className="has-text-info">*</span>
                    </div>
                    <div className="plan-items">
                      <div className="plan-item">$1800 minimum</div>
                    </div>
                    <div className="plan-footer">
                      <StyledLink to="/applications/family">
                        <button className="button is-fullwidth">Apply</button>
                      </StyledLink>
                    </div>
                  </div>

                  <div className="pricing-plan is-info">
                    <div className="plan-header">12-30 hours / week</div>
                    <div className="plan-price">
                      <span className="plan-price-amount">
                        <span className="plan-price-currency"></span>8
                      </span>
                      % of salary<span className="has-text-info">*</span>
                    </div>
                    <div className="plan-items">
                      {/* <div className="plan-item">12-30 hours / week</div> */}
                      {/* <div className="plan-item">Trial Day ($55)</div> */}
                      {/* <div className="plan-item">Trial Week ($225)</div> */}
                      <div className="plan-item">$1200 minimum</div>
                    </div>
                    <div className="plan-footer">
                      <StyledLink to="/applications/family">
                        <button className="button is-fullwidth">Apply</button>
                      </StyledLink>
                    </div>
                  </div>
                </div>
                <br></br>
                <div className="container">
                  <h4 className="subtitle is-4">Additional Charges</h4>
                  <p>
                    INFANT SPECIALIST - additional 1%
                    <span className="has-text-info">*</span>
                  </p>
                  <p>
                    HOUSE MANAGER - additional 2%
                    <span className="has-text-info">*</span>
                  </p>
                  <p>
                    TEACHER - additional 3%
                    <span className="has-text-info">*</span>
                  </p>
                  <p className="has-text-info">
                    * Of nanny’s total pay per year
                  </p>
                  <div className="is-divider"></div>
                </div>
                <br />
                <h3 className="subtitle is-3 has-text-centered">
                  Temporary Nanny Placements
                </h3>
                <p className="subtitle has-text-centered">
                  2-12 week commitment
                </p>
                <div className="pricing-table">
                  <div className="pricing-plan is-danger">
                    <div className="plan-header">>30 hours / week</div>
                    <div className="plan-price">
                      <span className="plan-price-amount">
                        <span className="plan-price-currency">$</span>145
                      </span>
                      / week
                    </div>
                    {/* <div className="plan-items"> */}
                      {/* <div className="plan-item">>30 hours / week</div> */}
                    {/* </div> */}
                    <div className="plan-footer">
                      <StyledLink to="/applications/family">
                        <button className="button is-fullwidth">Apply</button>
                      </StyledLink>
                    </div>
                  </div>

                  <div className="pricing-plan is-danger">
                    <div className="plan-header">20-30 hours / week</div>
                    <div className="plan-price">
                      <span className="plan-price-amount">
                        <span className="plan-price-currency">$</span>120
                      </span>
                      / week
                    </div>
                    {/* <div className="plan-items">
                      <div className="plan-item">20-30 hours / week</div>
                    </div> */}
                    <div className="plan-footer">
                      <StyledLink to="/applications/family">
                        <button className="button is-fullwidth">Apply</button>
                      </StyledLink>
                    </div>
                  </div>
                </div>
                <div className="pricing-table">
                  <div className="pricing-plan is-danger">
                    <div className="plan-header">10-20 hours / week</div>
                    <div className="plan-price">
                      <span className="plan-price-amount">
                        <span className="plan-price-currency">$</span>95
                      </span>
                      / week
                    </div>
                    {/* <div className="plan-items">
                      <div className="plan-item">10-20 hours / week</div>
                    </div> */}
                    <div className="plan-footer">
                      <StyledLink to="/applications/family">
                        <button className="button is-fullwidth">Apply</button>
                      </StyledLink>
                    </div>
                  </div>

                  <div className="pricing-plan is-danger">
                    <div className="plan-header">5-10 hours / week</div>
                    <div className="plan-price">
                      <span className="plan-price-amount">
                        <span className="plan-price-currency">$</span>70
                      </span>
                      / week
                    </div>
                    {/* <div className="plan-items">
                      <div className="plan-item">5-10 hours / week</div>
                    </div> */}
                    <div className="plan-footer">
                      <StyledLink to="/applications/family">
                        <button className="button is-fullwidth">Apply</button>
                      </StyledLink>
                    </div>
                  </div>
                </div>
                <br></br>
                <br></br>
                <br></br>
              </div>
            </div>
          </div>

          <div className="columns">
            <div className="column is-12">
              <h4 className="subtitle is-4">Nanny Replacement</h4>
              <p>
                Free/Included - if not a good fit within 25% of placement
                duration{" "}
              </p>
              <p>
                30% of above - if not a good fit within 50% of placement
                duration
              </p>
              <p>
                60% of above - if not a good fit within 75% of placement
                duration
              </p>
              <p>
                100% of above - if not a good fit after 75% of placement
                duration
              </p>
              <br></br>
              <p>
                WE WILL WORK TO FIND YOU A REPLACEMENT, BUT FOR OBVIOUS REASONS,
                NO REPLACEMENT IS GUARANTEED. TERMS AND CONDITIONS APPLY AND ARE
                CLEARLY LISTED ON OUR FAMILY/AGENCY CONTRACT.{" "}
              </p>
              <div className="is-divider"></div>
              <p>
                For {"<5"} Hours / week, {"<2"} weeks of care, occasional care,
                evening care, overnights, and Outdoor Babysitting please
                consider a Membership with{" "}
                <a href="https://www.myboulderbabysitter.com">
                  My Boulder Babysitter, LLC
                </a>
                !!
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PricingPage;
